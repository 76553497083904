define('ember-social-share/components/gplus-share-button', ['exports', 'ember-social-share/components/share-button', 'ember-social-share/templates/components/gplus-share-button'], function (exports, _shareButton, _gplusShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    layout: _gplusShareButton.default,
    shareURL: '//plus.google.com/share',
    classNames: ['gplus-share-button', 'share-button'],
    click() {
      let url = this.get('shareURL');
      url += '?url=' + encodeURIComponent(this.getCurrentUrl());
      this.openSharePopup(url);
    }
  });
});