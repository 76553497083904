define('liquid-fire/transitions/wait', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ms, opts, ...rest) {
    opts = opts !== undefined ? opts : {};

    return new Ember.RSVP.Promise(resolve => {
      setTimeout(() => {
        resolve(this.lookup(opts.then || 'default').call(this, ...rest));
      }, ms);
    });
  };
});