define('ember-carousel/components/carousel-body', ['exports', 'ember-carousel/templates/components/carousel-body'], function (exports, _carouselBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _carouselBody.default,
    classNames: ['carousel-body']
  });
});