define('liquid-fire/helpers/lf-lock-model', ['exports', 'liquid-fire/ember-internals'], function (exports, _emberInternals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lfLockModel = lfLockModel;
  function lfLockModel([routeInfo, outletName]) {
    // ensures that the name is locked, see implementation of `routeModel`
    (0, _emberInternals.routeModel)((0, _emberInternals.childRoute)(routeInfo, outletName));
    return routeInfo;
  }

  exports.default = Ember.Helper.helper(lfLockModel);
});