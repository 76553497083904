define("liquid-fire/rule", ["exports", "liquid-fire/action", "liquid-fire/constraint"], function (exports, _action, _constraint) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class Rule {
    constructor() {
      this.constraints = Ember.A();
      this.use = null;
      this.reverse = null;
    }

    add(thing) {
      if (thing instanceof _action.default) {
        let prop = 'use';
        if (thing.reversed) {
          prop = 'reverse';
        }
        if (this[prop]) {
          throw new Error(`More than one "${prop}" statement in the same transition rule is not allowed`);
        }
        this[prop] = thing;
      } else if (thing === 'debug') {
        this.debug = true;
      } else {
        this.constraints.push(thing);
      }
    }

    validate(transitionMap) {
      if (!this.use) {
        throw new Error(`Every transition rule must include a "use" statement`);
      }
      this.use.validateHandler(transitionMap);
      if (this.reverse) {
        this.reverse.validateHandler(transitionMap);
      }
      if (!this.constraints.find(c => c.target === 'firstTime')) {
        this.constraints.push(new _constraint.default('firstTime', 'no'));
      }
    }

    invert() {
      let rule = new this.constructor();
      rule.use = this.reverse;
      rule.reverse = this.use;
      rule.constraints = this.constraints.map(c => c.invert());
      rule.debug = this.debug;
      return rule;
    }
  }
  exports.default = Rule;
});