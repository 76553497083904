define("ember-cli-swiper/templates/components/swiper-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YPc4euro",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"swiper-wrapper\"],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"slide\"],[[27,\"component\",[\"swiper-slide\"],null]]]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasPagination\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"swiper-pagination\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"hasNavigation\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"nextElClass\"]]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"prevElClass\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs"
    }
  });

  _exports.default = _default;
});