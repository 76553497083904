enifed("@ember/-internals/views/lib/component_lookup", ["exports", "@ember/debug", "@ember/-internals/runtime"], function (_exports, _debug, _runtime) {
  "use strict";

  _exports.default = void 0;

  var _default = _runtime.Object.extend({
    componentFor(name, owner, options) {
      true && !(name.indexOf('-') > -1 || true
      /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
      ) && (0, _debug.assert)("You cannot use '" + name + "' as a component name. Component names must contain a hyphen" + (true
      /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
      ? ' or start with a capital letter' : '') + ".", name.indexOf('-') > -1 || true);
      let fullName = "component:" + name;
      return owner.factoryFor(fullName, options);
    },

    layoutFor(name, owner, options) {
      true && !(name.indexOf('-') > -1 || true
      /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
      ) && (0, _debug.assert)("You cannot use '" + name + "' as a component name. Component names must contain a hyphen.", name.indexOf('-') > -1 || true);
      let templateFullName = "template:components/" + name;
      return owner.lookup(templateFullName, options);
    }

  });

  _exports.default = _default;
});