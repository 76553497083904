define('ember-link-action/mixins/link-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      if (this.get('invokeAction')) {
        this._attachActionEvent();
      }
    },
    willDestroyElement() {
      if (this.get('invokeAction')) {
        this._detachActionEvent();
      }
    },

    _sendInvokeAction() {
      this.sendAction('invokeAction');
    },
    _attachActionEvent() {
      this.on(this.get('eventName'), this, this._sendInvokeAction);
    },
    _detachActionEvent() {
      this.off(this.get('eventName'), this, this._sendInvokeAction);
    }
  });
});