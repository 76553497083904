define('liquid-fire/components/liquid-child', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['liquid-child'],

    init() {
      this._super(...arguments);
      this._waitingFor = [];
    },

    didInsertElement() {
      if (this.element) {
        this.element.style.visibility = 'hidden';
      }
      this._waitForAll().then(() => {
        if (!this.isDestroying) {
          this._waitingFor = null;
          const didRenderAction = Ember.get(this, 'liquidChildDidRender');
          if (typeof didRenderAction === 'function') {
            didRenderAction(this);
          }
        }
      });
    },

    _isLiquidChild: true,
    _waitForMe(promise) {
      if (!this._waitingFor) {
        return;
      }
      this._waitingFor.push(promise);
      let ancestor = this.nearestWithProperty('_isLiquidChild');
      if (ancestor) {
        ancestor._waitForMe(promise);
      }
    },
    _waitForAll() {
      const promises = this._waitingFor;
      this._waitingFor = [];
      return Ember.RSVP.Promise.all(promises).then(() => {
        if (this._waitingFor.length > 0) {
          return this._waitForAll();
        }
      });
    }

  });
});