define("liquid-fire/transitions/scale", ["exports", "liquid-fire"], function (exports, _liquidFire) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = scale;
  function scale(opts = {}) {
    return (0, _liquidFire.animate)(this.oldElement, { scale: [0.2, 1] }, opts).then(() => {
      return (0, _liquidFire.animate)(this.newElement, { scale: [1, 0.2] }, opts);
    });
  }
});