define('ember-svg-jar/utils/make-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = makeHelper;
  function makeHelper(helperFunc) {
    let helper;

    if (Ember.Helper && Ember.Helper.helper) {
      helper = Ember.Helper.helper(function ([assetId], options) {
        return helperFunc(assetId, options);
      });
    } else {
      helper = Ember.Handlebars.makeBoundHelper(function (assetId, options) {
        return helperFunc(assetId, options.hash || {});
      });
    }

    return helper;
  }
});